import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SubmitClaimComponent } from './submit-claim/submit-claim.component';
import { LoginComponent } from './login/login.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AuthGuard } from './loginCore/auth.guard';
import { AuthService } from './loginCore/auth.service';
import { UserService } from './loginCore/user.service';
import { TokenInterceptor } from './loginCore/token.interceptor';
import { ClaimSearchPipe } from './claimsearch.pipe';
import { environment } from '../environments/environment';
import { ClaimService } from './claim.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { AccessService } from './access.service';
import { UtilService } from './util.service';
import { NgxLoadingModule } from 'ngx-loading';
import { LoadingService } from './loading.service';
import { FooterComponent } from './footer/footer.component';
import { provideUserIdleConfig } from 'angular-user-idle';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmployeeClaimdetailComponent } from './employee-claimdetail/employee-claimdetail.component';
import { EmployeeViewclaimComponent } from './employee-viewclaim/employee-viewclaim.component';
import { EmployeeViewquotaComponent } from './employee-viewquota/employee-viewquota.component';
import { EmployeeClaimreportComponent } from './employee-claimreport/employee-claimreport.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from '@angular/material/list';
import { CancelclaimdialogComponent } from './cancelclaimdialog/cancelclaimdialog.component';
import { HrViewclaimComponent } from './hr-viewclaim/hr-viewclaim.component';
import { HrClaimdetailComponent } from './hr-claimdetail/hr-claimdetail.component';
import { ReadmoredialogComponent } from './readmoredialog/readmoredialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HrSummaryreportComponent } from './hr-summaryreport/hr-summaryreport.component';
import { ConfirmclaimdialogComponent } from './confirmclaimdialog/confirmclaimdialog.component';
import { NossoComponent } from './nosso/nosso.component';
import { NgxCurrencyDirective } from "ngx-currency";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavigationComponent,
        SubmitClaimComponent,
        LoginComponent,
        FooterComponent,
        BreadcrumbComponent,
        AttachmentComponent,
        FileUploadComponent,
        EmployeeClaimdetailComponent,
        EmployeeViewclaimComponent,
        EmployeeViewquotaComponent,
        EmployeeClaimreportComponent,
        CancelclaimdialogComponent,
        HrViewclaimComponent,
        HrClaimdetailComponent,
        ClaimSearchPipe,
        ReadmoredialogComponent,
        HrSummaryreportComponent,
        ConfirmclaimdialogComponent,
        NossoComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgbModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFireAuthModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxLoadingModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatSortModule,
        MatSelectModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDialogModule,
        MatTooltipModule,
        NgSelectModule,
        MatListModule,
        NgxCurrencyDirective
    ],
    providers: [ClaimService, AccessService, AuthService, UserService, LoadingService, UtilService, AuthGuard, 
        provideUserIdleConfig({ idle: 600, timeout: 300, ping: 120 }),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
