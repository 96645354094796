// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
.redfont{
    color: red;
}
*/
.row.odd{
    background-color:#d9dce0;
    opacity: 0.8;
}

.row.even{
    background-color:#f7faff;
    opacity: 0.8;
}

.mat-list-base {
  padding-top: 0px;
  height: auto;
}

.mat-list-item {
  height: auto !important;
}

.applyPadding {
  padding: 0px 0px 0px 16px;
}
/*
.solvay-label{
    background-color: #e9ecef;
}

.label-quota{
    color:blue;
}

.past-quota-year{
    color:grey;
}

.current-quota-year{
    color:blue;
}
*/

.mdc-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mat-mdc-list-item, .mat-mdc-list-option {
    width: 100%;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    height: -moz-fit-content!important;
    height: fit-content!important;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/hr-claimdetail/hr-claimdetail.component.css"],"names":[],"mappings":"AAAA;;;;CAIC;AACD;IACI,wBAAwB;IACxB,YAAY;AAChB;;AAEA;IACI,wBAAwB;IACxB,YAAY;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;AACA;;;;;;;;;;;;;;;;CAgBC;;AAED;IACI,SAAS;IACT,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,0CAA0C;IAC1C,kCAA6B;IAA7B,6BAA6B;AACjC;;AAEA;EACE,qEAAqE;AACvE","sourcesContent":["/*\n.redfont{\n    color: red;\n}\n*/\n.row.odd{\n    background-color:#d9dce0;\n    opacity: 0.8;\n}\n\n.row.even{\n    background-color:#f7faff;\n    opacity: 0.8;\n}\n\n.mat-list-base {\n  padding-top: 0px;\n  height: auto;\n}\n\n.mat-list-item {\n  height: auto !important;\n}\n\n.applyPadding {\n  padding: 0px 0px 0px 16px;\n}\n/*\n.solvay-label{\n    background-color: #e9ecef;\n}\n\n.label-quota{\n    color:blue;\n}\n\n.past-quota-year{\n    color:grey;\n}\n\n.current-quota-year{\n    color:blue;\n}\n*/\n\n.mdc-list {\n    margin: 0;\n    padding: 0;\n    list-style-type: none;\n}\n\n.mat-mdc-list-item, .mat-mdc-list-option {\n    width: 100%;\n    box-sizing: border-box;\n    -webkit-tap-highlight-color: rgba(0,0,0,0);\n    height: fit-content!important;\n}\n\n.mat-mdc-list-base {\n  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
