// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-sort-header-container {
  align-items: center;
}

.clickable{
  cursor: pointer;
}

tr:hover {
  background: #edf3fc !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/hr-viewclaim/hr-viewclaim.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".mat-sort-header-container {\n  align-items: center;\n}\n\n.clickable{\n  cursor: pointer;\n}\n\ntr:hover {\n  background: #edf3fc !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
