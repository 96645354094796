// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auto{
    margin: auto;
}

.errMsg {
  color: red;
  font-size: 25px;
  text-align: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".auto{\n    margin: auto;\n}\n\n.errMsg {\n  color: red;\n  font-size: 25px;\n  text-align: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
