import { Component, OnInit } from '@angular/core';
import { AuthService } from '../loginCore/auth.service';
import { Router } from '@angular/router';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  //isHR: boolean;
  errMsg: string;

  constructor(public authService: AuthService, private router: Router, public loadingService: LoadingService) { }

  ngOnInit() {
    // disble laoding when load the page because by default loading enabled
    this.loadingService.disableLoading();
  }

  loginErrorMsg: string;

  tryGoogleLogin(){
    this.loadingService.enableLoading();
    this.authService.doGoogleLogin()
    .then(res => {
      this.loadingService.disableLoading();
      this.router.navigate(['/home']);
    })
    .catch(err => {
      this.loadingService.disableLoading();
      console.log('Error:', err.message);
      this.errMsg = err.message;
    });
  }

}
