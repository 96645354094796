import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Claim } from './claim';
import { environment } from './../environments/environment';
import { MyQuota } from './myquota';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export class ClaimFilter {
  public country: string;
  public status: string;
  public dateFrom: string;
  public dateTo: string;
  public employeeId: string;
  public employeeName: string;
  public receiptNo: string;
  public claimId: string;
}


@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  public claimInfo: Claim;
  public claimFilter: ClaimFilter = new ClaimFilter;

  constructor(private http: HttpClient) { }

  private claimUrl = environment.firebase.apiURL + 'claim/v1';
  private personUrl = environment.firebase.apiURL + 'person/v1';
  private attachmentUrl = environment.firebase.nonApiURL + 'attachment/?keyString=';

  getMyQuota(): Observable<any> {
    const url = `${this.claimUrl}/myQuota`;

    return this.http.get<any>(url)
      .pipe(
        map(myQuota => myQuota));
  }

  submitClaim(claim): Observable<any> {
    const url = `${this.claimUrl}/submit`;
    return this.http.post<any>(url, claim, httpOptions)
      .pipe(
        map(claim => { console.log(claim); return claim; })
      );
  }

  getClaims(): Observable<any> {
    const url = `${this.claimUrl}/list`;
    return this.http.get<any>(url)
      .pipe(
        map(claims => claims.items));
  }

  updateClaim(claim): Observable<any> {
    const url = `${this.claimUrl}/update`;
    return this.http.post(url, claim, httpOptions)
      .pipe(
        map(claim => { console.log(claim); return claim; })
      );
  }

  getMyClaims(): Observable<any> {
    const url = `${this.claimUrl}/listMy`;
    return this.http.get<any>(url)
      .pipe(
        map(claims => claims.items));
  }

  attachClaimReceipt(claimId: string, downloadUrl: string): Observable<any> {
    var body = JSON.stringify({ claimId: claimId, attachment: downloadUrl });
    const url = this.claimUrl + '/' + claimId + '/attach';
    return this.http.post(url, body, httpOptions)
      .pipe(
        map(claim => { return claim; }));
  }

  getAttachment(keyString: string) {
    const url = this.attachmentUrl + keyString;
    return this.http.get(url, { responseType: 'blob' });
  }

  deleteAttachment(keyString: string): Observable<any>{
    const regex = /id=(\d+)/;

    // Use the regex to find and extract the id
        const match = keyString.match(regex);
        let id;
    // Check if a match was found and extract the id
        if (match) {
          id = match[1]; // The id is captured in the first capturing group
          console.log(id); 
        } else {
          console.log("No id found in the input string.");
        }
        
        const url = `${this.claimUrl}/attachment/`+id;
    return this.http.delete(url).pipe(
      map(claim => { console.log(claim); return claim; })
      ,catchError(err => {
        return throwError(err.message);
      })
    )
  }

  getClaimInformation(): Claim {
    return this.claimInfo;
  };

  setClaimInformation(claimInformation: Claim) {
    this.claimInfo = claimInformation;
  };

formatClaimID(claimID): string {
    let formattedClaimID: string = "";
    claimID = ""+ claimID+"";
    for (var i = 0; i < claimID.length; i++) {
      if (i > 0 && i % 4 == 0) {
        formattedClaimID += '-';
      }
      formattedClaimID += claimID[i];
    }
    return formattedClaimID;
  }

  unformatClaimID(claimID): string {
    let nakedClaimID: string = "";

    if (claimID !== undefined) {
      for (var i = 0; i < claimID.length; i++) {
        if (claimID[i] != '-') {
          nakedClaimID += claimID[i];
        }
      }
    }
    return nakedClaimID;
  }

  formatDate(date): string {
    let formattedDate: string = "";
    if (date != null) {
      formattedDate = date.substr(0, 10);
    }
    return formattedDate;
  }

  formatDateReverse(longDate): string {
    let formattedDate: string = "";
    let splittedDate = null;
    if(longDate != null && longDate.indexOf("-") > 0){
      formattedDate = longDate.substr(0,10);
      splittedDate = formattedDate.split("-");
      formattedDate = splittedDate[2]+"."+splittedDate[1]+"."+splittedDate[0];
      return formattedDate;
    }else{
      return longDate.substr(0,10);
    }
  }

  formatDateBack(formattedReverseDate): string {
    let formattedDate: string = "";
    let splittedDate = null;
    if(formattedReverseDate != null){
      splittedDate = formattedReverseDate.split(".");
      formattedDate = splittedDate[2]+"-"+splittedDate[1]+"-"+splittedDate[0];
      return formattedDate;
    }else{
      return formattedReverseDate;
    }
  }

  formatMoney(money): string {
    let formattedMoney: string = "0.00";
    if (money != null) {
      formattedMoney = money.toFixed(2).replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });
    }
    return formattedMoney;
  }

  formatClaimItem(claimItems): string[]  {
    //let formattedItem: string = "";
    //let target = /\,/gi;
    //formattedItem = claimItems.replace(target, '<br />');
    let formattedItem;
    formattedItem = claimItems.split(',');
    return formattedItem;
  }
}
