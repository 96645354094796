// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  margin: 0px 20px 0px 20px;
}

.ng-invalid:not(form) {
    border-left: 0px solid #ffffff;
}

.ng-valid[required], .ng-valid.required {
    border-left: 0px solid #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/app/cancelclaimdialog/cancelclaimdialog.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":["button {\n  margin: 0px 20px 0px 20px;\n}\n\n.ng-invalid:not(form) {\n    border-left: 0px solid #ffffff;\n}\n\n.ng-valid[required], .ng-valid.required {\n    border-left: 0px solid #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
