// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  margin: 50px 0px 0px 0px;
  max-width:100px;
}

button {
  margin: 0px 50px 0px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["table {\n  margin: 50px 0px 0px 0px;\n  max-width:100px;\n}\n\nbutton {\n  margin: 0px 50px 0px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
