import { Injectable } from '@angular/core';
import { Observable, ObjectUnsubscribedError } from 'rxjs';
import { initializeApp } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/compat/auth'; // Import AngularFireAuth from the correct path
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from '../loading.service';

@Injectable()
export class UserService {

  private hrURL = environment.firebase.apiURL + 'hr/v1';
  private checkAccessURL = environment.firebase.apiURL + 'person/v1';
  public isHR: boolean;
  public canLogin: boolean;
  public hrMenu: string;

  constructor(public afAuth: AngularFireAuth, private http: HttpClient, public loadingService: LoadingService) {
  }

  disableLoading: number = 0;

  getCurrentUser(){
    return new Promise<any>((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });
  }

  getCanLoginAPI(): Observable<any> {
    const url = `${this.checkAccessURL}/hasAccess`;
    return this.http.get<any>(url);
  }

  getCanLogin() {
    return new Promise<any>((resolve, reject) => {
      this.getCanLoginAPI().subscribe(result => {
        this.canLogin = result.boolean;
        if (this.canLogin) {
          resolve(this.canLogin);
        } else {
          reject(new Error('Unauthorize Login!'));
        }
      })
    })
  }

  getHRRoleAPI(): Observable<any>{
    const url = `${this.hrURL}/ishr`;
    return this.http.get<any>(url);
  }

  getHRRole(): void{
    this.getHRRoleAPI().subscribe(result => {
      this.isHR = result.isHR;
      this.disableLoading += 1;
      if (this.disableLoading == 1) this.loadingService.disableLoading();
    });
  }

  setHRMenu(page: string){
    this.hrMenu = page;
  }

  getHRMenu(){
    return this.hrMenu;
  }

  user: Object = new Object;
  //function to get username from user service
  getUserName(): void{
    this.getCurrentUser().then(user => this.user = user);
  }

}
