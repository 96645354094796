// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bluetext{
    color: blue;
}

.redtext{
    color: red;
}

.smallContainer {
  margin-left: 25px;
  margin-right: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/app/employee-claimreport/employee-claimreport.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".bluetext{\n    color: blue;\n}\n\n.redtext{\n    color: red;\n}\n\n.smallContainer {\n  margin-left: 25px;\n  margin-right: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
