import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ClaimService } from '../claim.service';
import { Claim } from '../claim';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { filter } from 'rxjs/operators';
import {Sort} from '@angular/material/sort';


@Component({
  selector: 'app-employee-claimreport',
  templateUrl: './employee-claimreport.component.html',
  styleUrls: ['./employee-claimreport.component.css']
})
export class EmployeeClaimreportComponent implements OnInit {

  claims: Claim[];
  reportedClaims: Claim[];
  formattedReportedClaims: Claim[];
  disableLoading: number = 0;

  sortedData: Claim[];
  sort: Sort;

  constructor(
    public claimService: ClaimService,
    private loadingService: LoadingService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.getMyClaims();
    this.reportedClaims = [];
  }

  @ViewChild('reportpdf', {static: true}) reportContent: ElementRef;

  getMyClaims(): void {
    this.claimService.getMyClaims()
      .subscribe(claims => {
        this.claims = this.getAvailableClaims(claims);
        this.sortedData = this.claims;
        this.presortData();
        this.disableLoading += 1;
        if(this.disableLoading == 1) this.loadingService.disableLoading();
      });
  }

  getAvailableClaims(claims: Claim[]): Claim[] {
    let availableClaims: Claim[] = [];
    for (var i = 0; i < claims.length; i++) {
      if (claims[i].status == "Validated") {
        availableClaims.push(claims[i]);
      }
    }
    return availableClaims;
  }

  addReport(claimId: string): void {
    for (var i = 0; i < this.claims.length; i++) {
      if (claimId == this.claims[i].claimId) {
        this.reportedClaims.push(this.claims[i]);
        this.claims.splice(i, 1);
      }
    }
  }

  removeReport(claimId: string): void {
    for (var i = 0; i < this.reportedClaims.length; i++) {
      if (claimId == this.reportedClaims[i].claimId) {
        this.claims.push(this.reportedClaims[i]);
        this.reportedClaims.splice(i, 1);
      }
    }
  }

  clearReport(): void {
    location.reload();
  }

  download():void {
    this.formattedReportedClaims = this.reportedClaims;
    for(let i=0; i<this.formattedReportedClaims.length; i++){
      this.formattedReportedClaims[i].submissionDate = this.claimService.formatDateReverse(this.formattedReportedClaims[i].submissionDate);
      this.formattedReportedClaims[i].receiptDate = this.claimService.formatDateReverse(this.formattedReportedClaims[i].receiptDate);
    }
    var filteredReport = this.reportedClaims.map(({claimId, employeeName, employeeId, claimItems, country,
      claimAmount, approvedAmount, receiptNo, receiptDate, submissionDate }) => ({claimId, employeeName, employeeId, claimItems, country,
        claimAmount, approvedAmount, receiptNo, receiptDate, submissionDate}));

    for (const report of filteredReport) {
      console.log("Claim ID:", report.claimId);
      report.claimId=""+report.claimId+"";
      console.log("Claim ID:", report.claimId);
    }

    var headerClaim: Claim = new Claim();
    headerClaim.claimId = "Claim ID";
    headerClaim.employeeName = "Claim By";
    headerClaim.employeeId = "Employee ID";
    headerClaim.claimItems = "Claim Items";
    headerClaim.country = "Country";
    headerClaim.claimAmount = "Claim Amount";
    headerClaim.approvedAmount = "Approved Amount";
    headerClaim.receiptNo = "Receipt Number";
    headerClaim.receiptDate = "Receipt Date";
    headerClaim.submissionDate = "Submission Date";
    filteredReport.unshift(headerClaim);
    this.utilService.exportAsExcelFile(filteredReport, 'MyClaim_Summary_Report');
    for(let i=0; i<this.formattedReportedClaims.length; i++){
      this.formattedReportedClaims[i].submissionDate = this.claimService.formatDateBack(this.formattedReportedClaims[i].submissionDate);
      this.formattedReportedClaims[i].receiptDate = this.claimService.formatDateBack(this.formattedReportedClaims[i].receiptDate);
    }
 }

  sortData(sort: Sort) {
    this.sort = sort;
    const data = this.claims;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'claimId': return compare(a.claimId, b.claimId, isAsc);
        case 'employeeId': return compare(a.employeeId, b.employeeId, isAsc);
        case 'claimAmount': return compare(a.claimAmount, b.claimAmount, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        case 'submissionDate': return compare(a.submissionDate, b.submissionDate, isAsc);
        default: return 0;
      }
    });
    this.sortedData = data;
  }

  presortData(){
    const data = this.claims;

    data.sort((a, b) => {
      return compare(a.status, b.status, false);
    });

    this.sortedData = data;
    this.claims = data;

    const data2 = this.claims;

    data2.sort((a, b) => {
      return compare(a.submissionDate, b.submissionDate, false);
    });
    this.sortedData = data2;
    this.claims = data2;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
