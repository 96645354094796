import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../loading.service';
import { AuthService } from '../loginCore/auth.service';

@Component({
  selector: 'app-nosso',
  templateUrl: './nosso.component.html',
  styleUrls: ['./nosso.component.css']
})
export class NossoComponent implements OnInit {

  constructor(public authService: AuthService, public router: Router, public loadingService: LoadingService) { }

  ngOnInit() {
    // disble laoding when load the page because by default loading enabled
    this.loadingService.disableLoading();
  }

  username: string;
  password: string;
  displayName: string;
  msg: string;

  signup() {
    this.loadingService.enableLoading();
    if (this.username.toLowerCase() === 'testuser@solvay.com' || this.username.toLowerCase() === 'testhr@solvay.com') {
      this.authService.createUsernamePassword(this.username, this.password, this.displayName)
      .then(res => {
        this.msg = res;
      })
      .catch(err => {
        this.msg = err.message;
      })
    } else {
      this.msg = "You are not authorized to signup!"
    }
    this.loadingService.disableLoading();
  }

  login() {
    this.loadingService.enableLoading();
    console.log("Username = " + this.username);
    console.log("password = " + this.password);

    if (this.username.toLowerCase() === 'testuser@solvay.com' || this.username.toLowerCase() === 'testhr@solvay.com') {
      this.authService.doUsernamePasswordLogin(this.username, this.password)
      .then(res => {
        console.log('Success');
        this.loadingService.disableLoading();
        this.router.navigate(['/home']);
      }).catch(err => {
        console.log('Error:', err.message);
        this.msg = err.message;
      });
    } else {
      this.msg = "You are not authorized to login!"
    }
    this.loadingService.disableLoading();
  }

}
