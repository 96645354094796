// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb li {
    display: inline;
}

.breadcrumb li+li:before {
    content: " > ";
    color: white;
}

.breadcrumb{
    background-color: #8d0000;
    padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/breadcrumb/breadcrumb.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".breadcrumb li {\n    display: inline;\n}\n\n.breadcrumb li+li:before {\n    content: \" > \";\n    color: white;\n}\n\n.breadcrumb{\n    background-color: #8d0000;\n    padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
