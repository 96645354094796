import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-cancelclaimdialog',
  templateUrl: './cancelclaimdialog.component.html',
  styleUrls: ['./cancelclaimdialog.component.css']
})
export class CancelclaimdialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CancelclaimdialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
  }

  selectedReason: string;

  confirm(selectedReason) {
      this.dialogRef.close(selectedReason);
  }

  close() {
      this.dialogRef.close();
  }

}
