// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solvay-label{
    /*background-color: #e9ecef;*/
    background-color: transparent;
}

.label-quota{
    color:blue;
}

.border {
  border: 1px solid black !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/employee-viewquota/employee-viewquota.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,6BAA6B;AACjC;;AAEA;IACI,UAAU;AACd;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".solvay-label{\n    /*background-color: #e9ecef;*/\n    background-color: transparent;\n}\n\n.label-quota{\n    color:blue;\n}\n\n.border {\n  border: 1px solid black !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
