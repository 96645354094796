import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { ClaimService } from '../claim.service';

@Component({
  selector: 'app-confirmclaimdialog',
  templateUrl: './confirmclaimdialog.component.html',
  styleUrls: ['./confirmclaimdialog.component.css']
})
export class ConfirmclaimdialogComponent implements OnInit {

  constructor(
    public claimService: ClaimService,
    private dialogRef: MatDialogRef<ConfirmclaimdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  close() {
      this.dialogRef.close();
  }

  confirm(action) {
      this.dialogRef.close(action);
  }

}
