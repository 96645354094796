import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public retainValue: boolean = false;

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {skipHeader:true});
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + "_" + new  Date().getTime() + EXCEL_EXTENSION);
  }

  public keepValue(): void{
    this.retainValue = true;
  }

  public getRetainValue(): boolean{
    return this.retainValue;
  }

  public getQuotaYears(){
    var today = new Date();
    var currentYear = today.getFullYear();
    var lastYear = (today.getFullYear() - 1);
    var quotaYears = [currentYear, lastYear];
    return quotaYears;
  }
}
