import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { UserService } from '../loginCore/user.service';
import { Person } from '../person';
import { MyQuota } from '../myquota';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { Claim } from '../claim';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-viewquota',
  templateUrl: './employee-viewquota.component.html',
  styleUrls: ['./employee-viewquota.component.css']
})
export class EmployeeViewquotaComponent implements OnInit {

  constructor(
    public claimService: ClaimService,
    public userService: UserService,
    private loadingService: LoadingService,
    public utilService: UtilService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadingService.enableLoading();
    this.getUserName();
    this.getMyQuota();
  }

  user: Object = new Object;
  //function to get username from user service
  getUserName(): void {
    this.userService.getCurrentUser().then(user => this.user = user);
  }

  myQuota: MyQuota;

  disableLoading: number = 0;

  getMyQuota() {
    this.claimService.getMyQuota()
      .subscribe(myQuota => {
        this.myQuota = myQuota;
        this.disableLoading += 1;
        if (this.disableLoading == 1) this.loadingService.disableLoading();
      });
  }

}
