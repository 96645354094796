// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centrePlaceholder {
    text-align: center;
}

.card-header {
  background-color: rgba(0,0,0,.07);
}

.card-body {
  background-color: rgba(0,0,0,.03);
}

.card {
  background-color: transparent
}
`, "",{"version":3,"sources":["webpack://./src/app/file-upload/file-upload.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE;AACF","sourcesContent":[".centrePlaceholder {\n    text-align: center;\n}\n\n.card-header {\n  background-color: rgba(0,0,0,.07);\n}\n\n.card-body {\n  background-color: rgba(0,0,0,.03);\n}\n\n.card {\n  background-color: transparent\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
