import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../loading.service';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { MatDialogConfig } from "@angular/material/dialog";
import { ReadmoredialogComponent } from '../readmoredialog/readmoredialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadingService.disableLoading();
  }

  // open dialog box for change a claim status
  openDialog() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ReadmoredialogComponent, dialogConfig);

  }

  submitClaim() {
      this.router.navigate(['/submitClaim']);
  }

}
