import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { UserService } from '../loginCore/user.service';

@Injectable()
export class AuthService {

  constructor(public afAuth: AngularFireAuth, public userService: UserService) { }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({ 'hd': 'solvay.com' });
      this.afAuth.signInWithPopup(provider)
        .then(value => {
          var userinfo = value.user;
          this.userService.getCanLogin().then(canLogin => {
            if (canLogin) {
              console.log('Nice, it worked! Welcome ' + userinfo.displayName);
              resolve(value);
            }
          }).catch(err => {
            console.log('Unauthorize to login to this portal!', err.message);
            reject(err);
          })
        })
        .catch(err => {
          console.log('Something went wrong:', err.message);
          reject(err);
        });
    });
  }

  createUsernamePassword(email, password, userDisplayName) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(value => {
        var currentUser = firebase.auth().currentUser;
        currentUser.updateProfile({
          displayName: userDisplayName
        });
        resolve("Account created successfully. Please proceed to login.")
      })
      .catch(function(error) {
        console.log(error.code);
        console.log(error.message);
        reject(error);
      });
    })
  }

  doUsernamePasswordLogin(email, password) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then(res => {
        this.userService.getCanLogin().then(canLogin => {
          if (canLogin) {
            resolve(res);
          }
        })
        .catch(err => {
          console.log('Unauthorize to login to this portal!', err.message);
          reject(err);
        })
      })
      .catch(function(error) {
        reject(error)
      });
    })
  }

  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.signOut()
        console.log('Logout successfully!');
        resolve();
      }
      else {
        reject();
      }
    });
  }

}
