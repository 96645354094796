// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-horizontal{
    text-align: right;
}

.hr-menu{
  width: 200px;
}

.vertical-middle{
  margin-left: 10px;
  margin-top: 13px;
  vertical-align: top;
}
`, "",{"version":3,"sources":["webpack://./src/app/navigation/navigation.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".right-horizontal{\n    text-align: right;\n}\n\n.hr-menu{\n  width: 200px;\n}\n\n.vertical-middle{\n  margin-left: 10px;\n  margin-top: 13px;\n  vertical-align: top;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
