import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MyQuota } from '../myquota';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { UserService } from '../loginCore/user.service';
import { Claim } from '../claim';
import { MatDialog } from "@angular/material/dialog";
import { MatDialogConfig } from "@angular/material/dialog";
import { CancelclaimdialogComponent } from '../cancelclaimdialog/cancelclaimdialog.component';

@Component({
  selector: 'app-hr-claimdetail',
  templateUrl: './hr-claimdetail.component.html',
  styleUrls: ['./hr-claimdetail.component.css']
})
export class HrClaimdetailComponent implements OnInit {


  claimDetail: Claim;

  claimId: string;
  notes: string;
  personId: string;
  employeeId: string;

  claimAmount: number;
  availableAmount: number;

  quotaYear: string;
  currentYear: string;
  lastYear: string;

  disableLoading: number = 0;

  claim: object = [];
  status = [
    { id: 1, label: 'Cancelled' }
  ];
  selectedStatus: string;
  systemMessage: string;

  constructor(
    public claimService: ClaimService,
    public userService: UserService,
    private loadingService: LoadingService,
    private router: Router,
    public snackBar: MatSnackBar,
    public utilService: UtilService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.claimService.getClaimInformation() === undefined) {
      this.claimDetail = JSON.parse(localStorage.getItem('claimObj'));
      this.claimAmount = this.convertStringtoNumber(this.claimDetail.claimAmount);
    } else {
      this.claimDetail = this.claimService.getClaimInformation();
      this.claimAmount = this.convertStringtoNumber(this.claimService.getClaimInformation().claimAmount);
      localStorage.setItem('claimObj', JSON.stringify(this.claimService.getClaimInformation()));
    }
    this.selectedStatus = this.claimDetail.status;
    this.userService.setHRMenu("hr");
  }

  // open dialog box for change a claim status
  openDialog() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CancelclaimdialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {
            if (data !== undefined) {
              this.loadingService.enableLoading();
              this.updateClaim(data)
            }
        }
    );
  }

  convertStringtoNumber(amount: string): number {
    return Number(amount);
  }

  selectedStatusHandler(event: any) {
    // expect to return selected id
    this.selectedStatus = this.status[event - 1].label;
  }

  updateClaim(selectedReason): void {
    this.claim = {
      claimId: this.claimDetail.claimId, status: this.selectedStatus, notes: selectedReason
    }

    this.claimService.updateClaim(this.claim).subscribe(
      (result) => {
        this.openSnackbar('Claim has been successfully updated.');
        this.router.navigate(['/hrViewClaim']);
      },
      (err) => {
        this.openAlertSnackbar('There is an error: ' + err.message);
        this.loadingService.disableLoading();
      }
    );
  }

  cancel(): void {
    this.utilService.keepValue();
    this.router.navigate(['/hrViewClaim']);
  }

  openSnackbar(msg: string) {
    this.snackBar.open(msg, "Got it!",
      {
        duration: 3000
      });
  }

  openAlertSnackbar(msg: string) {
    this.snackBar.open(msg, "Dismiss",
      {
        duration: 10000,
        panelClass: ["snack-error"]
      });
  }

}
