// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    padding: 15px;
    text-align: center;
    font-size: 10pt;
    bottom: 0;
    /* position: fixed;
    align: center;
    width: 100% */
    background-color: transparent !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/footer/footer.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT;;iBAEa;IACb,wCAAwC;AAC5C","sourcesContent":[".footer{\n    padding: 15px;\n    text-align: center;\n    font-size: 10pt;\n    bottom: 0;\n    /* position: fixed;\n    align: center;\n    width: 100% */\n    background-color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
