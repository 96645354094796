// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputtext{
    width:250px; margin:5px;
}
/*
.inputtext{
    width:250px; margin:5px; text-indent:10px;
}
*/
.dropdown{
    width:250px; margin:5px;
}

.solvay-label{
    background-color: #e9ecef;
}

.label-quota{
    color:blue;
}

.past-quota-year{
    color:grey;
}

.current-quota-year{
    color:blue;
}

.btn-success.disabled, .btn-success:disabled {
  /*background: #dddddd;*/
  opacity: 0.2
}
`, "",{"version":3,"sources":["webpack://./src/app/submit-claim/submit-claim.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,UAAU;AAC3B;AACA;;;;CAIC;AACD;IACI,WAAW,EAAE,UAAU;AAC3B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;EACE,uBAAuB;EACvB;AACF","sourcesContent":[".inputtext{\n    width:250px; margin:5px;\n}\n/*\n.inputtext{\n    width:250px; margin:5px; text-indent:10px;\n}\n*/\n.dropdown{\n    width:250px; margin:5px;\n}\n\n.solvay-label{\n    background-color: #e9ecef;\n}\n\n.label-quota{\n    color:blue;\n}\n\n.past-quota-year{\n    color:grey;\n}\n\n.current-quota-year{\n    color:blue;\n}\n\n.btn-success.disabled, .btn-success:disabled {\n  /*background: #dddddd;*/\n  opacity: 0.2\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
